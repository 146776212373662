import ACTIONS from '../../common/contants/store/companyAction';

const initialState = {
  isInvalidated: true
};
/*
   company: {
    isFetching: boolean
    company: object
    isInvalidated: boolean
  }
*/
const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.COMPANY_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        company: action.company
      };
    case ACTIONS.COMPANY_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.COMPANY_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default companyReducer;
