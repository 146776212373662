import ACTIONS from '../../common/contants/store/applicantAppliedJobAction';

const initialState = {
  isInvalidated: true
};
/*
   applicantAppliedJob: {
    isFetching: boolean
    applicantAppliedJob: object
    isInvalidated: boolean
  }
*/
const applicantAppliedJobReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.APPLICANT_APPLIED_JOB_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.APPLICANT_APPLIED_JOB_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        applicantAppliedJob: action.applicantAppliedJob
      };
    case ACTIONS.APPLICANT_APPLIED_JOB_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.APPLICANT_APPLIED_JOB_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default applicantAppliedJobReducer;
