import ACTIONS from '../../common/contants/store/profileAction';
import profileApi from '../../network/userProfileAPI';

const fetchProfileInit = () => {
  return {
    type: ACTIONS.PROFILE_FETCH_INIT
  };
};

const fetchProfileFail = () => {
  return {
    type: ACTIONS.PROFILE_FETCH_FAIL
  };
};

const fetchProfileSuccess = user => {
  return {
    type: ACTIONS.PROFILE_FETCH_SUCCESS,
    user: user
  };
};

const invalidateprofile = () => {
  return {
    type: ACTIONS.PROFILE_INVALIDATE
  };
};

const fetchProfile = (userSub, userRole) => {
  return function(dispatch, getState) {
    const currentState = getState().profile;
    if (!currentState.isInvalidated) return null;

    dispatch(fetchProfileInit());
    profileApi
      .getUser(userSub, userRole)
      .then(result => {
        dispatch(fetchProfileSuccess(result.data));
      })
      .catch(err => {
        dispatch(fetchProfileFail());
      });
  };
};

export default {
  fetchProfile,
  invalidateprofile
};
