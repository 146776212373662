import ACTIONS from '../../common/contants/store/codingAssessmentAction';

const initialState = {};
/*
  codingassessments: {
    [questionId]: {
      isFetching: boolean
      codingassessments: {
        ...questiondata
      }
      isInvalidated: boolean
    }
  }
*/
const assessmentCodingReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CODING_ASSESSMENT_FETCH_INIT:
      return {
        ...state,
        [action.questionId]: {
          isFetching: true,
          isInvalidated: false
        }
      };
    case ACTIONS.CODING_ASSESSMENT_FETCH_SUCCESS:
      return {
        ...state,
        [action.questionId]: {
          isFetching: false,
          codingassessment: action.data
        }
      };
    case ACTIONS.CODING_ASSESSMENT_FETCH_FAIL:
      return {
        ...state,
        [action.questionId]: {
          isFetching: false
        }
      };
    case ACTIONS.CODING_ASSESSMENT_INVALIDATE:
      return {
        ...state,
        [action.questionId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default assessmentCodingReducer;
