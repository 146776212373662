import * as firebase from 'firebase/app';
import 'firebase/analytics';

import stageUtils from '../utils/stageUtils';

const FIREBASE_EVENTS = {
  screen_view: 'screen_view',
  apply_home: 'apply_home',
  COMPANY_PROFILE_EDIT: 'COMPANY_PROFILE_EDIT',
  COMPANY_PROFILE_SAVE: 'COMPANY_PROFILE_SAVE',
  COMPANY_SAVE_PROFILE_IMAGE: 'COMPANY_SAVE_PROFILE_IMAGE',
  COMPANY_SAVE_PROFILE_CHANGE: 'COMPANY_SAVE_PROFILE_CHANGE',
  COMPANY_POSTED_EDIT_JOB: 'COMPANY_POSTED_EDIT_JOB',
  COMAPNY_POSTED_SAVE_EDIT_JOB: 'COMAPNY_POSTED_SAVE_EDIT_JOB',
  COMPANY_POST_NEW_JOB: 'COMPANY_POST_NEW_JOB',
  COMPANY_SHORTLISTED_APPLICANT: 'COMPANY_SHORTLISTED_APPLICANT',
  COMAPNY_REJECTED_APPLICANT: 'COMPANY_REJECTED_APPLICANT',
  COMPANY_SEE_CANDIDATE_DETAILS: 'COMPANY_SEE_CANDIDATE_DETAILS',
  COMPANY_ADD_DETAILS: 'COMPANY_ADD_DETAILS',
  JOB_PROFILE_EDIT: 'JOB_PROFILE_EDIT',
  JOB_PROFILE_BUILD: 'JOB_PROFILE_BUILD',
  JOB_PROFILE_SAVE_IMAGE: 'JOB_PROFILE_SAVE_IMAGE',
  JOB_PROFILE_UPDATED: 'JOB_PROFILE_UPDATED',
  JOB_PROFILE_ADD_SKILL: 'JOB_PROFILE_ADD_SKILL',
  JOB_PROFILE_DETAILS: 'JOB_PROFILE_DETAILS',
  JOB_APPLY: 'JOB_APPLY',
  ASSESSMENT_STARTED: 'ASSESSMENT_STARTED',
  QUESTIONS_SUBMITTED: 'QUESTIONS_SUBMITTED',
  CODING_STARTED: 'CODING_STARTED',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_FAIL: 'SIGNUP_FAIL',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAIL: 'LOGIN_FAIL',
  HIRING_SIGNUP_SUCCESS: 'HIRING_SIGNUP_SUCCESS',
  HIRING_SIGNUP_FAIL: 'HIRING_SIGNUP_FAIL',
  FEEDBACK_MODEL_FAIL: 'FEEDBACK_MODEL_FAIL',
  FEEDBACK_MODEL_SUCCESS: 'FEEDBACK_MODEL_SUCCESS',
  CONTENT_PDF_VIEW: 'CONTENT_PDF_VIEW',
  CONTENT_VIDEO_VIEW: 'CONTENT_VIDEO_VIEW'
};

const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyCxwn0CeDwJibpTdQtqqbyyqwdipigWLJI',
  authDomain: 'lrned-react-app.firebaseapp.com',
  databaseURL: 'https://lrned-react-app.firebaseio.com',
  projectId: 'lrned-react-app',
  storageBucket: 'lrned-react-app.appspot.com',
  messagingSenderId: '1062379271155',
  appId: '1:1062379271155:web:e9073b3775aa1df34f5886',
  measurementId: 'G-L3YDBX8W0P'
};

// Do not log events or data for non PRODUCTION stages
const isFirebaseEnabled = () => {
  if (stageUtils.getAppStage() !== 'PRODUCTION') {
    return false;
  }
  return true;
};

// Initialize Firebase
const setupFirebase = () => {
  if (!isFirebaseEnabled()) {
    return;
  }
  firebase.initializeApp(FIREBASE_CONFIG);
  firebase.analytics();
};

const setupUser = userId => {
  if (!isFirebaseEnabled()) {
    return;
  }
  firebase.analytics().setUserId(userId);
};

const logEvent = (event, metadata = {}) => {
  if (!isFirebaseEnabled()) {
    return;
  }
  firebase.analytics().logEvent(event, metadata);
};

const logScreen = screenName => {
  if (!isFirebaseEnabled()) {
    return;
  }
  firebase.analytics().logEvent(FIREBASE_EVENTS.screen_view, {
    screen_name: screenName
  });
};

const logUser = (userId, userProperties = {}) => {
  if (!isFirebaseEnabled()) {
    return;
  }
  firebase.analytics().setUserId(userId);
  firebase.analytics().setUserProperties(userProperties);
};

export default {
  FIREBASE_EVENTS,
  setupFirebase,
  logEvent,
  logScreen,
  logUser,
  setupUser
};
