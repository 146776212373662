import ACTIONS from '../../common/contants/store/feedbackAction';

const initialState = {};

/*
feedback: {
    [batchId]: {
        isFetching: boolean
        feedback: array
        isInvalidated: boolean
    }
}
*/

const feedbackReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FEEDBACK_FETCH_INIT:
      return {
        ...state,
        [action.batchId]: {
          isFetching: true,
          isInvalidated: false
        }
      };
    case ACTIONS.FEEDBACK_FETCH_SUCCESS:
      return {
        ...state,
        [action.batchId]: {
          isFetching: false,
          feedback: action.feedback
        }
      };
    case ACTIONS.FEEDBACK_FETCH_FAIL:
      return {
        ...state,
        [action.batchId]: {
          isFetching: false
        }
      };
    case ACTIONS.FEEDBACK_INVALIDATE:
      return {
        ...state,
        [action.batchId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default feedbackReducer;
