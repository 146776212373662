import ACTIONS from '../../common/contants/store/jobAction';

const initialState = {
  isInvalidated: true
};
/*
   company: {
    isFetching: boolean
    company: object
    isInvalidated: boolean
  }
*/
const jobReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.JOB_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.JOB_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        job: action.job
      };
    case ACTIONS.JOB_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.JOB_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default jobReducer;
