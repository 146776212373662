import ACTIONS from '../../common/contants/store/contentDetailsAction';

const initialState = {};
/*
  contentDetails: { 
      [contentId]:{
          isFetching: boolean
          contentDetails: array
          isInvalidated: boolean 
        }
      }
*/

const contentDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CONTENT_DETAILS_FETCH_INIT:
      return {
        ...state,
        [action.contentId]: {
          isFetching: true,
          isInvalidated: false
        }
      };
    case ACTIONS.CONTENT_DETAILS_FETCH_SUCCESS:
      return {
        ...state,
        [action.contentId]: {
          contentId: action.contentId,
          isFetching: false,
          contentDetails: action.contentDetails
        }
      };
    case ACTIONS.CONTENT_DETAILS_FETCH_FAIL:
      return {
        ...state,
        [action.contentId]: {
          isFetching: false
        }
      };
    case ACTIONS.CONTENT_DETAILS_INVALIDATE:
      return {
        ...state,
        [action.contentId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default contentDetailsReducer;
