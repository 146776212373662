import ACTIONS from '../../common/contants/store/jobsWaitlistAction';

const initialState = {
  isInvalidated: true
};
/*
  waitlist: {
    isFetching: boolean
    data: any
    isInvalidated: boolean
  }
*/
const jobsWaitlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.JOBS_WAITLIST_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
        isAuthenticated: false
      };
    case ACTIONS.JOBS_WAITLIST_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    case ACTIONS.JOBS_WAITLIST_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.JOBS_WAITLIST_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default jobsWaitlistReducer;
