const awsConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:fa0ad6ce-c1fa-4b27-9cb4-7881fb7106d9',
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_q7nXu1h5j',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '2v0kqn0v4bcsq8r685le3f5l0e',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'dev-lrned-app-backend',
        endpoint: 'https://ojni5zzxde.execute-api.us-west-2.amazonaws.com/dev'
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'lrned-applicant-resume-bucket', //REQUIRED -  Amazon S3 bucket
      region: 'us-west-2'
    }
  }
};

export default awsConfig;
