import ACTIONS from '../../common/contants/store/contentAction';

const initialState = {
  isInvalidated: true
};
/*
  content: {
    isFetching: boolean
    content: array
    isInvalidated: boolean
  }
*/
const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CONTENT_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.CONTENT_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        content: action.content
      };
    case ACTIONS.CONTENT_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.CONTENT_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default contentReducer;
