const ROLES = {
  ADMIN_ROLE: 'ADMIN',
  INSTRUCTOR_ROLE: 'INSTRUCTOR',
  USER_ROLE: 'USER',
  APPLICANT_ROLE: 'APPLICANT',
  HIRING_PARTNER_ROLE: 'HIRING_PARTNER',
  // Not a ROLE but an Userpool attribute to decide role
  LRNED_ROLE_ATTRIBUTE: 'custom:lrned_role'
};

export default ROLES;
