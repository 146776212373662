import ACTIONS from '../../common/contants/store/applicantsAction';

const initialState = {};
/*
   applicants: {
    isFetching: boolean
    list: object
    isInvalidated: boolean
  }
*/
const applicantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.APPLICANTS_FETCH_INIT:
      return {
        ...state,
        [action.jobId]: {
          isFetching: true,
          isInvalidated: false,
          jobId: action.jobId
        }
      };
    case ACTIONS.APPLICANTS_FETCH_SUCCESS:
      return {
        ...state,
        [action.jobId]: {
          isFetching: false,
          list: action.applicants,
          jobId: action.jobId
        }
      };
    case ACTIONS.APPLICANTS_FETCH_FAIL:
      return {
        ...state,
        [action.jobId]: {
          isFetching: false
        }
      };
    case ACTIONS.APPLICANTS_INVALIDATE:
      return {
        ...state,
        [action.jobId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default applicantsReducer;
