import ROLES from '../common/contants/userRole';
import utils from './utils';

const SUBDOMAINS = {
  APP: 'app',
  APPLY: 'apply',
  JOBS: 'jobs',
  HIRE: 'hire'
};

const getSubDomain = () => {
  if (utils.isNull(window)) return null;
  const [sub] = window.location.hostname.split('.');
  if (sub === 'localhost') return SUBDOMAINS.APPLY; //Only for development purpose
  return sub;
};

const getUserRoleForDomain = () => {
  let domain = getSubDomain();
  switch (domain) {
    case SUBDOMAINS.APPLY:
      return ROLES.APPLICANT_ROLE;
    case SUBDOMAINS.APP:
      return ROLES.USER_ROLE;
    case SUBDOMAINS.HIRE:
      return ROLES.HIRING_PARTNER_ROLE;
    default:
      return null;
  }
};

const getAppLinkForUserRole = role => {
  switch (role) {
    case ROLES.APPLICANT_ROLE:
      return 'https://apply.lrned.io';
    case ROLES.USER_ROLE:
    case ROLES.INSTRUCTOR_ROLE:
    case ROLES.ADMIN_ROLE:
      return 'https://app.lrned.io';
    default:
      return null;
  }
};

export default {
  SUBDOMAINS,
  getSubDomain,
  getUserRoleForDomain,
  getAppLinkForUserRole
};
