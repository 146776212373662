import AWS_CONFIG_DEV from '../aws-exports-dev';
import AWS_CONFIG_PROD from '../aws-exports-prod';

const STAGES = {
  DEVELOPMENT: 'DEVELOPMENT',
  PRODUCTION: 'PRODUCTION'
};

// change zone start

const CURRENT_STAGE = STAGES.PRODUCTION; // change to prod for deployment

// Get Stage

const getAppStage = () => {
  if (CURRENT_STAGE === STAGES.PRODUCTION) {
    return STAGES.PRODUCTION;
  } else {
    return STAGES.DEVELOPMENT;
  }
};

// change zone end

const getAWSConfig = () => {
  if (CURRENT_STAGE === STAGES.PRODUCTION) {
    return AWS_CONFIG_PROD;
  } else {
    return AWS_CONFIG_DEV;
  }
};

// change s3 bucket

const getAWS3Bucket = () => {
  if (CURRENT_STAGE === STAGES.PRODUCTION) {
    return 'lrned-coding-assessment-bucket-prod';
  } else {
    return 'lrned-coding-assessment-bucket-dev';
  }
};

const getBucketRegion = () => {
  if (CURRENT_STAGE === STAGES.PRODUCTION) {
    return 'ap-south-1';
  } else {
    return 'us-west-2';
  }
};

const getAWS3ProfileImageBucket = () => {
  if (CURRENT_STAGE === STAGES.PRODUCTION) {
    return 'lrned-profile-image-bucket-prod';
  } else {
    return 'lrned-profile-image-bucket-dev';
  }
};

export default {
  getAWSConfig,
  getAWS3Bucket,
  getBucketRegion,
  getAWS3ProfileImageBucket,
  getAppStage
};
