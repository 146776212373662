import ACTIONS from '../../common/contants/store/sessionsAction';

const initialState = {};
/*
  sessions: {
    [batchId]: {
      isFetching: boolean
      sessions: {
        contentId: session object
      }
      isInvalidated: boolean
    }
  }
*/
const sessionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SESSIONS_FETCH_INIT:
      return {
        ...state,
        [action.batchId]: {
          isFetching: true,
          isInvalidated: false,
          isAuthenticated: false
        }
      };
    case ACTIONS.SESSIONS_FETCH_SUCCESS:
      return {
        ...state,
        [action.batchId]: {
          isFetching: false,
          sessions: action.sessions
        }
      };
    case ACTIONS.SESSIONS_FETCH_FAIL:
      return {
        ...state,
        [action.batchId]: {
          isFetching: false
        }
      };
    case ACTIONS.SESSIONS_INVALIDATE:
      return {
        ...state,
        [action.batchId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default sessionsReducer;
