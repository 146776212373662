import { connect } from 'react-redux';
import Amplify from 'aws-amplify';
import React, { Component, Suspense, lazy } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import authenticationAction from './store/auth/authenticationAction';
import domainUtils from './utils/domainUtils';
import GSpinner from './common/components/Spinner/GSpinner';
import stageUtils from './utils/stageUtils';
import utils from './utils/utils';

import './App.scss';

// --------Lazy loading--------
const ApplyLayout = lazy(() => import('./layouts/apply'));
const Authentication = lazy(() => import('./layouts/authentication'));
const MainLayout = lazy(() => import('./layouts/main'));
const NotFound = lazy(() => import('./layouts/notfound/NotFound'));
const JobsLayout = lazy(() => import('./layouts/jobs'));
const HireLayout = lazy(() => import('./layouts/hire'));

const awsconfig = stageUtils.getAWSConfig();
Amplify.configure(awsconfig);

class App extends Component {
  componentDidMount() {
    this.props.dispatch(authenticationAction.checkAuthentication());
  }

  //  --------------- Rendering ----------------
  renderSpinner() {
    return <GSpinner />;
  }

  renderAuthentication() {
    // Need to render different signup page for hiring partners
    return <Authentication />;
  }

  renderMainLayout() {
    if (this.props.profile.isFetching) return this.renderSpinner();
    return <MainLayout userRole={this.props.authUserRole} />;
  }

  renderNoPageFound() {
    return <NotFound />;
  }

  renderApplyLayout() {
    return <ApplyLayout userRole={this.props.authUserRole} />;
  }

  renderJobsLayout() {
    return <JobsLayout userRole={this.props.authUserRole} />;
  }

  renderHireLayout() {
    return <HireLayout userRole={this.props.authUserRole} />;
  }

  renderApp() {
    if (!this.props.isAuthenticated) {
      return this.renderAuthentication();
    }
    const appHost = domainUtils.getSubDomain();
    switch (appHost) {
      case domainUtils.SUBDOMAINS.APP:
        return this.renderMainLayout();
      case domainUtils.SUBDOMAINS.APPLY:
        return this.renderApplyLayout();
      case domainUtils.SUBDOMAINS.JOBS:
        return this.renderJobsLayout();
      case domainUtils.SUBDOMAINS.HIRE:
        return this.renderHireLayout();
      default:
        return this.renderNoPageFound();
    }
  }

  render() {
    return (
      <div>
        <Suspense fallback={this.renderSpinner()}>
          {this.props.isFetching || utils.isNull(this.props.isAuthenticated)
            ? this.renderSpinner()
            : this.renderApp()}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { authentication, profile } = state;
  return {
    isFetching: authentication.isFetching,
    isAuthenticated: authentication.isAuthenticated,
    authUserRole: authentication.userRole,
    profile: profile
  };
};

export default connect(mapStateToProps)(App);
