import { Auth } from 'aws-amplify';

import stageUtils from './stageUtils';
import utils from './utils';

const getAuthHeader = async () => {
  const session = await Auth.currentSession();

  if (
    !utils.isNull(session) &&
    !utils.isNull(session.idToken) &&
    !utils.isNull(session.idToken.jwtToken)
  ) {
    return {
      Authorization: session.idToken.jwtToken
    };
  } else return null;
};

const getAPIName = () => {
  const awsConfig = stageUtils.getAWSConfig();
  return awsConfig.API.endpoints[0].name;
};

const getCompilerAPIName = () => {
  return 'http://ec2-3-7-45-15.ap-south-1.compute.amazonaws.com:3000';
};

const getUserCred = () => {
  return {
    key: 'AKIAS5EI4PVGI6AGYCPU',
    secret: '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'
  };
};

export default {
  getAuthHeader,
  getAPIName,
  getCompilerAPIName,
  getUserCred
};
