// ---------- Form validation starts-------------
const emailRegex = RegExp(/^[a-zA-Z0-9._*+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/);
const corporateEmailRegex = RegExp(
  /^[\w.+\-]+@gmail|@yahoo|@hotmail|@outlook\.com$/
);

const phoneRegex = RegExp(/^((\+){1}(1|91)){1}[1-9]{1}[0-9]{9}$/);

const formValid = (state, errors) => {
  let valid = true;

  // validate form errors being empty
  Object.values(errors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(state).forEach(val => {
    (val === null || val === '') && (valid = false);
  });

  return valid;
};

const formValidApplicant = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    (val === null || val === '') && (valid = false);
  });

  return valid;
};

const formValidData = objectData => {
  let valid = true;

  // validate the form was filled out
  Object.values(objectData).forEach(val => {
    (val === null || val === '') && (valid = false);
  });

  return valid;
};

const formValidEmpty = ({ formErrors, ...rest }) => {
  let valid = true;

  // validate form errors being empty
  Object.values(formErrors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form was filled out
  Object.values(rest).forEach(val => {
    val === '' && (valid = false);
  });

  return valid;
};

// ---------- Form validation ends-------------

// ---------- Data validation starts-------------

const isNull = item => {
  return item === undefined || item === null;
};

const isStringEmpty = str => {
  if (
    str.constructor === String &&
    (str.length === 0 || str === '__EMPTY__' || str === ' ')
  ) {
    return true;
  }
  return false;
};

const isDataEmpty = data => {
  if (isNull(data)) return true;
  if (isStringEmpty(data)) return true;
  if (
    (data.constructor === Object && Object.keys(data).length === 0) ||
    (data.constructor === Array && data.length === 0)
  ) {
    return true;
  }
  return false;
};

// ---------- Data validation ends-------------

// ---------- Data manipulation starts---------

const getFirstName = user => {
  if (isNull(user) || isNull(user.firstName)) {
    return '';
  } else return user.firstName;
};

const getLastName = user => {
  if (isNull(user) || isNull(user.lastName)) {
    return '';
  } else return user.lastName;
};

const getUserId = user => {
  if (isNull(user) || isNull(user.userId)) {
    return null;
  } else return user.userId;
};

const getEmail = user => {
  if (isNull(user) || isNull(user.emailId)) {
    return '';
  } else return user.emailId;
};

const getMobileNumber = user => {
  if (isNull(user) || isNull(user.mobile)) {
    return '';
  } else return user.mobile;
};

const sortData = (data, keyToSort) => {
  // expecting data as "array of objects"
  if (isDataEmpty(data)) return [];
  return data.sort((itemA, itemB) => {
    return itemA[keyToSort] - itemB[keyToSort];
  });
};

const sortDataByDate = (data, keyToSort) => {
  // expecting data as "array of objects"
  if (isDataEmpty(data)) return [];
  return data.sort((itemA, itemB) => {
    //getting sorted data in descending order
    return new Date(itemB[keyToSort]) - new Date(itemA[keyToSort]);
  });
};

const whiteSpaceToHyphen = str => {
  return str.replace(/ /g, '-');
};

const getFullName = user => {
  if (isDataEmpty(user)) return '';
  const fName =
    isDataEmpty(user.firstName) || user.firstName === '__EMPTY__'
      ? ''
      : user.firstName;
  const lName =
    isDataEmpty(user.lastName) || user.lastName === '__EMPTY__'
      ? ''
      : user.lastName;
  return `${fName} ${lName}`;
};

const shuffleArray = array => {
  if (isDataEmpty(array)) return [];
  let index = array.length;
  // While there remain elements to shuffle…
  while (index) {
    // Pick a remaining element…
    let currentIndex = Math.floor(Math.random() * index--);
    // And swap it with the current element.
    let temp = array[index];
    array[index] = array[currentIndex];
    array[currentIndex] = temp;
  }
  return array;
};

const currencyFormatter = amount => {
  // Return to Indian currency format string
  if (isNull(amount)) return '';
  if (typeof amount !== 'number') return amount;
  return amount.toLocaleString('en-IN');
};
// ---------- Data manipulation ends---------

export default {
  emailRegex,
  phoneRegex,
  formValid,
  formValidEmpty,
  isNull,
  isDataEmpty,
  getFirstName,
  getLastName,
  sortData,
  sortDataByDate,
  whiteSpaceToHyphen,
  getFullName,
  formValidApplicant,
  getUserId,
  getEmail,
  getMobileNumber,
  formValidData,
  shuffleArray,
  currencyFormatter,
  corporateEmailRegex
};
