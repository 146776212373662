import ACTIONS from '../../common/contants/store/skillsAction';

const initialState = {
  isInvalidated: true
};
/*
  skills: {
    isFetching: boolean
    list: [
      {
        skillId: id
        skillName: name
      }
    ]
    isInvalidated: boolean
  }
*/
const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SKILLS_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.SKILLS_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.skills
      };
    case ACTIONS.SKILLS_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.SKILLS_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default skillsReducer;
