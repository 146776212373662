import ACTIONS from '../../common/contants/store/jobProfileAction';

const initialState = {
  isInvalidated: true
};
/*
  jobProfile: {
    isFetching: boolean
    user: any
    isInvalidated: boolean
  }
*/
const jobProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.JOB_PROFILE_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
        isAuthenticated: false
      };
    case ACTIONS.JOB_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        user: action.user
      };
    case ACTIONS.JOB_PROFILE_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.JOB_PROFILE_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default jobProfileReducer;
