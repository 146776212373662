import ACTIONS from '../../common/contants/store/batchAction';

const initialState = {
  isInvalidated: true
};
/*
  batches: {
    isFetching: boolean
    batches: object
    isInvalidated: boolean
  }
*/
const batchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BATCH_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.BATCH_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        batches: action.batches
      };
    case ACTIONS.BATCH_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.BATCH_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default batchReducer;
