import { API } from 'aws-amplify';

import awsUtils from '../utils/awsUtils';
import ROLES from '../common/contants/userRole';
import utils from '../utils/utils';

//  To get user profile
async function getUser(userSub, userRole) {
  if (utils.isNull(userSub)) return null;
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  let path = null;
  switch (userRole) {
    case ROLES.HIRING_PARTNER_ROLE:
      path = '/hiring/profile/' + userSub;
      break;
    case ROLES.APPLICANT_ROLE:
      path = '/applicant/' + userSub;
      break;
    default:
      path = '/user/' + userSub;
  }

  const myInit = {
    headers: {
      ...authHeader
    }
  };
  return await API.get(apiName, path, myInit);
}

//  To get all user List except admins
async function getUserList() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = '/users';
  const myInit = {
    headers: {
      ...authHeader
    }
  };
  return await API.get(apiName, path, myInit);
}

export default {
  getUser,
  getUserList
};
