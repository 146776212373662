const ACTIONS = {
  AUTHENTICATION_CHECK_INIT: 'AUTHENTICATION_CHECK_INIT',
  AUTHENTICATION_CHECK_SUCCESS: 'AUTHENTICATION_CHECK_SUCCESS',
  AUTHENTICATION_CHECK_FAIL: 'AUTHENTICATION_CHECK_FAIL',
  AUTHENTICATION_CHECK_INVALIDATE: 'AUTHENTICATION_CHECK_INVALIDATE',
  USER_LOGGEDIN_SUCCESS: 'USER_LOGGEDIN_SUCCESS',
  USER_LOGGEDOUT_SUCCESS: 'USER_LOGGEDOUT_SUCCESS'
};

export default ACTIONS;
