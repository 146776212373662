import ACTIONS from '../../common/contants/store/graphAction';

const initialState = {
  isInvalidated: true
};
/*
  graph: {
    isFetching: boolean
    graph: object
    date:array
    isInvalidated: boolean
  }
*/
const graphReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GRAPH_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false
      };
    case ACTIONS.GRAPH_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        graph: action.graph
      };
    case ACTIONS.GRAPH_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.GRAPH_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    case ACTIONS.GRAPH_DATE_UPDATE:
      return {
        ...state,
        date: action.date
      };
    default:
      return state;
  }
};

export default graphReducer;
