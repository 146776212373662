import ACTIONS from '../../common/contants/store/userListAction';

const initialState = {
  isInvalidated: true
};
/*
  userList: {
    isFetching: boolean
    list: {
      userId: user
    } 
    isInvalidated: boolean
  }
*/
const userListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.USERLIST_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
        isAuthenticated: false
      };
    case ACTIONS.USERLIST_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        list: action.userlist
      };
    case ACTIONS.USERLIST_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.USERLIST_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default userListReducer;
