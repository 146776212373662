import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import ACTIONS from './common/contants/store/authenticationAction';
import allJobProfileReducer from './store/profile/allJobProfileReducer';
import applicantsReducer from './store/hiring/applicantsReducer';
import assessmentReducer from './store/assessment/assessmentReducer';
import authenticationReducer from './store/auth/authenticationReducer';
import batchReducer from './store/batch/batchReducer';
import codingAssessmentReducer from './store/codingAssessment/codingAssessmentReducer';
import companyJobReducer from './store/job/companyJobReducer';
import companyProfileReducer from './store/hiring/companyProfileReducer';
import companyReducer from './store/hiring/companyReducer';
import contentDetailsReducer from './store/contentDetails/contentDetailsReducer';
import contentReducer from './store/content/contentReducer';
import coursesReducer from './store/courses/coursesReducer';
import applicantAppliedJobReducer from './store/job/applicantAppliedJobReducer';
import feedbackReducer from './store/feedback/feedbackReducer';
import graphReducer from './store/dashboard/graphReducer';
import jobProfileReducer from './store/profile/jobProfileReducer';
import jobReducer from './store/job/jobReducer';
import profileReducer from './store/profile/profileReducer';
import sessionsReducer from './store/sessions/sessionsReducer';
import skillsReducer from './store/skills/skillsReducer';
import userListReducer from './store/profile/userListReducer';
import jobsWaitlistReducer from './store/waitlist/jobsWaitlistReducer';

// Add every new reducer here---
const appReducer = combineReducers({
  assessments: assessmentReducer,
  authentication: authenticationReducer,
  codingassessment: codingAssessmentReducer,
  content: contentReducer,
  company: companyReducer,
  companyProfile: companyProfileReducer,
  courses: coursesReducer,
  contentDetails: contentDetailsReducer,
  job: jobReducer,
  applicantAppliedJob: applicantAppliedJobReducer,
  companyJob: companyJobReducer,
  profile: profileReducer,
  batches: batchReducer,
  userList: userListReducer,
  sessions: sessionsReducer,
  graph: graphReducer,
  feedback: feedbackReducer,
  jobProfile: jobProfileReducer,
  skills: skillsReducer,
  applicants: applicantsReducer,
  allJobProfile: allJobProfileReducer,
  jobsWaitlist: jobsWaitlistReducer
});

const rootReducer = (state, action) => {
  // Droping store on logout
  if (action.type === ACTIONS.USER_LOGGEDOUT_SUCCESS) {
    state = undefined;
  }

  return appReducer(state, action);
};

//  Defining store, Don't make any change here
const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
