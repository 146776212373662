import ACTIONS from '../../common/contants/store/authenticationAction';

const initialState = {};
/*
  authentication: {
    isFetching: boolean
    isAuthenticated: boolean
    userSub: string
    userRole: string
  }
*/
const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.AUTHENTICATION_CHECK_INIT:
      return {
        ...state,
        isFetching: true,
        isAuthenticated: false
      };
    case ACTIONS.AUTHENTICATION_CHECK_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        userSub: action.userSub,
        userRole: action.userRole
      };
    case ACTIONS.AUTHENTICATION_CHECK_FAIL:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false
      };
    case ACTIONS.USER_LOGGEDIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        userSub: action.userSub,
        userRole: action.userRole
      };
    default:
      return state;
  }
};

export default authenticationReducer;
