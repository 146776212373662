import { Auth } from 'aws-amplify';

import ACTIONS from '../../common/contants/store/authenticationAction';
import profileAction from '../profile/profileAction';
import ROLES from '../../common/contants/userRole';

const checkAuthenticationInit = () => {
  return {
    type: ACTIONS.AUTHENTICATION_CHECK_INIT
  };
};

const checkAuthenticationFail = () => {
  return {
    type: ACTIONS.AUTHENTICATION_CHECK_FAIL
  };
};

const checkAuthenticationSuccess = (userSub, userRole) => {
  return {
    type: ACTIONS.AUTHENTICATION_CHECK_SUCCESS,
    userSub: userSub,
    userRole: userRole
  };
};

const checkAuthentication = () => {
  return function(dispatch, getState) {
    dispatch(checkAuthenticationInit());
    Auth.currentAuthenticatedUser({
      bypassCache: true // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(user => {
        const userSub = user.attributes.sub;
        const userRole = user.attributes[ROLES.LRNED_ROLE_ATTRIBUTE];
        dispatch(profileAction.fetchProfile(userSub, userRole));
        dispatch(checkAuthenticationSuccess(userSub, userRole));
      })
      .catch(err => {
        dispatch(checkAuthenticationFail());
      });
  };
};

const userLoggedInSuccess = (userSub, userRole) => {
  return {
    type: ACTIONS.USER_LOGGEDIN_SUCCESS,
    userSub: userSub,
    userRole: userRole
  };
};

const userLoggedIn = user => {
  return function(dispatch, getState) {
    const userSub = user.attributes.sub;
    const userRole = user.attributes[ROLES.LRNED_ROLE_ATTRIBUTE];
    dispatch(profileAction.fetchProfile(userSub, userRole));
    dispatch(userLoggedInSuccess(userSub, userRole));
  };
};

const userLoggedOutSuccess = () => {
  return {
    type: ACTIONS.USER_LOGGEDOUT_SUCCESS
  };
};

const userLoggedOut = () => {
  return function(dispatch) {
    dispatch(userLoggedOutSuccess());
    dispatch(checkAuthentication());
  };
};

export default {
  checkAuthentication,
  userLoggedIn,
  userLoggedOut
};
