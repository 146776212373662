const awsConfig = {
  Auth: {
    //REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'ap-south-1:ced6ed79-275a-4e6a-a14e-64f9b3f674a8',
    // REQUIRED - Amazon Cognito Region
    region: 'ap-south-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'ap-south-1_NtcInkGlP',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '5ogbdar1dctjl4t7rid0k2j3cr',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'prod-lrned-app-backend',
        endpoint: 'https://heobo2hikg.execute-api.ap-south-1.amazonaws.com/prod'
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'lrned-applicant-resume-bucket', //REQUIRED -  Amazon S3 bucket
      region: 'us-west-2'
    }
  }
};

export default awsConfig;
