import ACTIONS from '../../common/contants/store/allJobProfileAction';

const initialState = {
  isInvalidated: true
};
/*
  allJobProfile: {
    isFetching: boolean
    allJobProfile: any
    isInvalidated: boolean
  }
*/
const allJobProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ALL_JOB_PROFILE_FETCH_INIT:
      return {
        ...state,
        isFetching: true,
        isInvalidated: false,
        isAuthenticated: false
      };
    case ACTIONS.ALL_JOB_PROFILE_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        allJobProfile: action.allJobProfile
      };
    case ACTIONS.ALL_JOB_PROFILE_FETCH_FAIL:
      return {
        ...state,
        isFetching: false
      };
    case ACTIONS.ALL_JOB_PROFILE_INVALIDATE:
      return {
        ...state,
        isInvalidated: true
      };
    default:
      return state;
  }
};

export default allJobProfileReducer;
