import ACTIONS from '../../common/contants/store/assessmentAction';

const initialState = {};
/*
  assessments: {
    [questionId]: {
      isFetching: boolean
      assessment: {
        ...questiondata
      }
      isInvalidated: boolean
    }
  }
*/
const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ASSESSMENT_FETCH_INIT:
      return {
        ...state,
        [action.questionId]: {
          isFetching: true,
          isInvalidated: false
        }
      };
    case ACTIONS.ASSESSMENT_FETCH_SUCCESS:
      return {
        ...state,
        [action.questionId]: {
          isFetching: false,
          assessment: action.data
        }
      };
    case ACTIONS.ASSESSMENT_FETCH_FAIL:
      return {
        ...state,
        [action.questionId]: {
          isFetching: false
        }
      };
    case ACTIONS.ASSESSMENT_INVALIDATE:
      return {
        ...state,
        [action.questionId]: {
          isInvalidated: true
        }
      };
    default:
      return state;
  }
};

export default assessmentReducer;
